export default
`#### Labour costs (%)

_“Wages and salaries”_ _“Personnel costs”_ is equivalent to Labour costs.

Labour costs are defined as the total remuneration, in cash or in kind,
payable by an employer to an employee (regular and temporary employees as well as home workers)
in return for work done by a latter during the reference period.

Include taxes and employees’ social security contributions retained by the unit as
well as the employer’s compulsory and voluntary social contributions.

- In %: Total annual labour costs (€) divided by the total annual production cost. Then multiply the result by 100.`;